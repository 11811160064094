import React from 'react'

export default function SidebarSection({
    title,
    children,
    className = '',
}: {
    title?: string
    children: React.ReactNode
    className?: string
}) {
    return (
        <div
            className={`border-b border-dashed border-gray-accent-light py-4 px-3 dark:border-gray-accent-dark lg:px-6 ${className}`}
        >
            {title && <h3 className=" m-0 mb-2 text-sm font-semibold opacity-25 dark:text-white">{title}</h3>}
            {children}
        </div>
    )
}
