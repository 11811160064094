import { MDXProvider } from '@mdx-js/react'
import PostLayout from 'components/PostLayout'
import SidebarSection from 'components/PostLayout/SidebarSection'
import Contributors, { Contributor } from 'components/PostLayout/Contributors'
import PageViews from 'components/PostLayout/PageViews'
import Topics from 'components/PostLayout/Topics'
import { NewsletterForm } from 'components/NewsletterForm'
import { Check, Close } from 'components/Icons/Icons'
import Layout from 'components/Layout'
import Link from 'components/Link'
import { Section } from 'components/Section'
import { SEO } from 'components/seo'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { MdxCodeBlock } from '../components/CodeBlock'
import { shortcodes } from '../mdxGlobalComponents'
import { SEO2 } from 'components/seo2'
import { getSeoImage } from 'utils/googleData'
import { getDirection } from 'utils/translations'
const articleWidth = {
    lg: 'max-w-screen-2xl',
    md: 'max-w-5xl',
    sm: 'max-w-2xl',
    full: 'w-full px-0',
}

const A = (props) => <Link {...props} className="text-red hover:text-red font-semibold" />

// export const Head = ({ location, params, data: { pageData }, pageContext: { menu } }) => (
//     <SEO2
//         title={pageData?.frontmatter?.title + ''}
//         description={pageData?.frontmatter?.description || pageData?.excerpt}
//         article
//         image={pageData?.frontmatter?.featuredImage?.publicURL || pageData?.parent?.cover?.image}
//         noindex={pageData?.frontmatter?.noindex}
//     />
// )
const BlogPostSidebar = ({ contributors , date, filePath, title, tags, location, pageViews }) => {
    return (
        <>
            {contributors && (
                <SidebarSection>
                    <Contributors contributors={contributors} />
                </SidebarSection>
            )}
            {pageViews?.length > 0 && (
                <SidebarSection>
                    <PageViews pageViews={pageViews?.toLocaleString()} />
                </SidebarSection>
            )}
            {tags?.length > 0 && (
                <SidebarSection title={`Tag${tags?.length === 1 ? '' : 's'}`}>
                    <Topics
                        topics={tags?.map((tag) => ({ name: tag, url: `/blog/tags/${slugify(tag, { lower: true })}` }))}
                    />
                </SidebarSection>
            )}
            <SidebarSection>
                <NewsletterForm sidebar />
            </SidebarSection>
        </>
    )
}

export default function Plain(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { pageData, blogPosts, documentationPosts, tutorialsPosts, tutorialsRecent,customersPosts } = data
    const { tableOfContents, menu, brand     } = pageContext
    const { body, excerpt, fields, frontmatter, parent } = pageData
    const { date, featuredImage, title, name, showTitle, hideAnchor, sidebar, slug, featuredVideo, featuredImageType, contributors, contributors2, description, tags, category, breadcrumb, locale = 'en', width = 'sm', noindex, images,    seoTitle,
    seoDescription,seoimage,layout = 'article',hideSidebar } = frontmatter
    const otherImages = images || parent?.footer?.image
    const isArticleLayout = layout === 'article';
    const filePath = parent?.relativePath
    const dir = getDirection(locale)
    const contributorsData = contributors || contributors2
    const featuredImg = featuredImage?.publicURL || parent?.cover?.image
    const components = {
      ...shortcodes(pageProps),
        pre: MdxCodeBlock,
        Check,
        Close,
        a: A,
    }
      // Determine which layout to render
  const renderContent = (layout) => {
    switch (layout) {
      case 'post':
        return (
            <PostLayout
                dir={dir}
                article={isArticleLayout}
                stickySidebar
                title={title}
                hideSidebar
                hideBreadcrumb
                contentWidth={790}
                filePath={filePath}
                tableOfContents={tableOfContents}
                breadcrumb={breadcrumb}
                menu={menu}
                hideSurvey
                sidebar={
                    <BlogPostSidebar
                        tags={tags}
                        contributors={contributorsData}
                        date={date}
                        filePath={filePath}
                        title={title}
                        location={location}
                        pageViews={fields?.pageViews}
                    />
                }
            >
            <MDXProvider components={components}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </PostLayout>
        );
      case 'article':
      default:
        return (
            <article className={`mx-auto my-12 md:my-24 px-4 article-content ${articleWidth[width]}`}>
            {showTitle && title && <h1 className="text-center">{title}</h1>}
            <MDXProvider components={components}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </article>
        );
    }
  };

    return (
        <Layout >
                <SEO
        title={seoTitle || pageData?.frontmatter?.title + ` - ${brand}` }
        description={seoDescription || pageData?.frontmatter?.description || pageData?.excerpt}
        article
        image={getSeoImage({ googleImages: [parent?.images, parent?.images2] }) || featuredImg }
        noindex={noindex}
    />
      {renderContent(layout)}
            <Section />
        </Layout>
    )
}

export const query = graphql`
    query PlainLayout(
        $id: String!
        $blogTags: [String]
        $tutorialTags: [String]
        $documentationURLs: [String]
        $customerURLs: [String]
    ) {
    pageData: mdx(id: {eq: $id}) {
      body
      excerpt
      fields {
        slug
        pageViews
      }
      frontmatter {
        date
        hideSidebar
        hideSurvey
        layout
        title
        description
        name
        slug
        sidebar
        showTitle
        locale
        template
        type
        hideAnchor
        breadcrumb {
          name
          slug
        }
        tags
        category
        featuredImageType
        seoTitle
        seoDescription
        seoimage
        featuredVideo
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
        width
        noindex
        contributors: authorData {
          frontmatter {
            handle
            link_type
            link_url
            name
            role: jobTitle
            image {
              childrenImageSharp {
                gatsbyImageData(width: 38, height: 38)
              }
            }
          }
          id
          name
          parent {
            ... on GoogleDocs {
              cover {
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(width: 38, height: 38)
                  }
                }
              }
            }
          }
        }
        contributors2: authorData2 {
          id
          name
          role
          handle
          image {
            childImageSharp {
              gatsbyImageData(width: 38, height: 38)
            }
          }
        }
      }
      parent {
        ... on GoogleDocs {
          id
          images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          images2: images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 2000
                quality: 50
                webpOptions: {quality: 70}
                breakpoints: [760]
              )
            }
          }
          downloadedFiles: downloadedFiles {
            id
            name
            fileContent
            absolutePath
          }
          cover {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          footer {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    blogPosts: allMdx(
        filter: { fields: { slug: { regex: "/^/blog/" } }, frontmatter: { tags: { in: $blogTags } } }
        limit: 6
    ) {
        edges {
            node {
                ...BlogFragment
            }
        }
    }
    tutorialsPosts: allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { fields: { slug: { regex: "/^/tutorials/" } }, frontmatter: { tags: { in: $tutorialTags } } }
        limit: 6
    ) {
            edges {
                node {
                    ...TutorialFragment
                }
            }
        }
        tutorialsRecent: allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { slug: { regex: "/^/tutorials/.+/" } } }
          limit: 4
      ) {
          edges {
              node {
                  ...BlogFragment
              }
          }
      }
    documentationPosts: allMdx(filter: { fields: { slug: { in: $documentationURLs } } }) {
        nodes {
            fields {
                slug
            }
            frontmatter {
                title
            }
            headings {
                depth
                value
            }
        }
    }
    customersPosts: allMdx(filter: { fields: { slug: { in: $customerURLs } } }) {
        nodes {
            fields {
                slug
            }
            frontmatter {
                customer
                title
                logo {
                    publicURL
                }
            }
            body
        }
    }
    tutorialsPopular: allMdx(
      sort: { order: DESC, fields: [fields___pageViews] }
      filter: { fields: { slug: { regex: "/^/tutorials/.+/" } } }
      limit: 4
  ) {
      edges {
          node {
              ...BlogFragment
          }
      }
  }
  tutorialsCategories: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { slug: { regex: "/^/tutorials/.+/" } } }
  ) {
      group(field: frontmatter___tags, limit: 2) {
          category: fieldValue
          edges {
              node {
                  ...BlogFragment
              }
          }
      }
}
  }

`
